/** IMPORTS **/
@import 'functions', 'mixins', '_custom_bootstrap_variables';
@import '_base';


.mainContainer{
  display: flex;
  @include media-breakpoint-down(md){
    flex-direction: column;
  }
    transition: all 5s ease-in-out;
  .nameContainer{
    background-color: #15161c;
    width: 25%;
    transition: width .5s ease;
    .actions{
      display: flex;
      justify-content: space-between;
      .collapseIcon{
        margin-top: .5rem;
        .navButton{
          color: #ffffff;
          font-size: 1.5rem;
          text-align: left;
          &.expand{
            display: none;
          }
          i{
            display: inline-block;
            &.grow {
              transition: all .2s ease-in-out;
              &:hover {
                transform: scale(1.2);
                color: #b7b7b7;
                cursor:pointer;
              }
            }
          }
        }

      }
      button{
        background: TRANSPARENT;
        color: #fff;
        padding: .5rem 1rem;
        border-radius: 6px;
        border: #fff 2px solid;
        font-family: EB Garamond,serif;
        font-size: 1rem;
        font-weight: 600;
        &:hover{
          cursor: pointer;
          background-color: #ffffff;
          color: #000000;
        }
        @include media-breakpoint-down(md){
          margin-top: 10px;
        }
      }
    }
    &.collapse{
      width: 10%;
      .bioContent{
        font-size: 25px;
        h1{
          font-size: 1.5rem;
        }
        .blip{
          display: none;
        }
        .navButton{
          width: 50%;
          &.expand{
            display: block;
          }
          &.shrink{
            display: none;
          }
        }
        button{
          font-size: 14px;
         padding: .4rem .6rem;
        }

      }
    }
    @include media-breakpoint-down(md){
      width: 100%;
    }
    .bioContent{
      font-size: 40px;
      margin: 1rem;
      position: sticky;
      top: 34%;
      @include media-breakpoint-down(md){
        margin: 20% 20px;
        font-size: 30px;
      }
      .name{
        margin: 0;
        color: #fff;
      }
      .designer{
        margin: 0;
        background-image: linear-gradient(to left, violet,indigo);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .developer{
        margin: 0;
        background-image: linear-gradient(to left, #1d1d1d,#5a5a5a,#d3d3d3);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .blip{
        color: #ffffff;
        font-size: 25px;
        @include media-breakpoint-down(sm){
          font-size: 16px;
        }
      }
    }
  }
  .contentContainer{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 75%;
    &.collapse{
      width: 90%;
    }
    p{
      line-height: 25px;
      margin-top: 3%;
    }
    @include media-breakpoint-down(md){
      width: 100%;
      height: 100%;
    }
    @include media-breakpoint-down(sm){
      margin: 0px auto;
      p {
        font-size: 14px;
      }
      h3{
        font-size: 16px;
      }
    }
    }
    .portalIntro{
      .projectContainer{
        &.quadSquares{
          overflow: hidden;
          .twoProjHolder{
            display: flex;
            justify-content: center;
            width:100%;
            height: 50vh;
            .tileExpandIcon{
              display: none;
            }
            .tileShrinkIcon{
              display: none;
            }
            @include media-breakpoint-down(sm){
              flex-direction: column;
              height: auto;
              .tileExpandIcon{
                display: block;
              }
            }
            .projects{
              display: flex;
              justify-content: center;
              padding: 1rem;
              width: 50vw;
              height: auto;
              border-width: 12px;
              border-style: solid;
              background-color: #fbfbfb;
              text-align: center;
              &.active{
                background-color: rgb(85, 199, 195);
                h2{
                  color: #fff;
                }
                .tileShrinkIcon{
                  @include media-breakpoint-down(md){
                    display: block;
                    color: #ffffff;
                  }
                }
                .tileExpandIcon{
                  display: none;
                }
              }
              &:hover{
                cursor: pointer;
                h2{
                  color: #fff;
                }
                .tileExpandIcon{
                  color: #ffffff;
                }
              }
              h2{
                font-weight: 500;
              }
              &:hover{
                .projDisplay{
                  &.grow{
                    transition: all .2s ease-in-out;
                  }
                  &.grow:hover{
                    transform: scale(1.05);
                  }
                }
              }
              @include media-breakpoint-down(sm){
                width: auto;
                text-align: center;
                h2{
                  font-size: 1rem;
                }
              }
              a{
                margin: auto;
                text-decoration: none;
              }
              &.projOne{
                border-color: rgb(85, 199, 195);
                &.active{
                  background-color: rgb(85, 199, 195);
                }
                &:hover{
                  background-color: rgb(85, 199, 195);
                }
              }
             &.projTwo{
               border-color: rgb(85, 199, 195);
               &:active{
                 background-color: rgb(85, 199, 195);
               }
               &:hover{
                 background-color: rgb(85, 199, 195);
               }
              }
              &.projThree{
                border-color: rgb(85, 199, 195);
                &:active{
                  background-color: rgb(85, 199, 195);
                }
                &:hover{
                  background-color: rgb(85, 199, 195);
                }
              }
              &.projFour{
                border-color: rgb(85, 199, 195);
                &:active{
                  background-color: rgb(85, 199, 195);
                }
                &:hover{
                  background-color: rgb(85, 199, 195);
                }
              }
              .innerDiv{
                width: 90%;
                margin: auto;
                .projDisplay{
                  width: 90%;
                }
                &.collapse{
                  .projDisplay{
                    width: 80%;
                  }
                }
              }
            }
          }
        }
        &.horzSquares{
          display: flex;
          flex-direction: row;
          overflow: auto;
          .twoProjHolder{
            height: 30vh;
            @include media-breakpoint-up(lg) {
              @media (orientation: landscape) {
                height: 45vh;
              }
              @media (orientation: portrait) {
                height: 45vh;
              }
            }
            @include media-breakpoint-down(md) {
              @media (orientation: landscape) {
                height: 45vh;
              }
              @media (orientation: portrait) {
                height: 45vh;
              }
            }
            .innerDiv{
              width: 90%;
              margin: auto;
              .projDisplay{
                width: 90%;
              }
            }
            .projects{
              .tileShrinkIcon{
                display: none;
              }
              width: auto;
              height: auto;
              cursor: pointer;
              @include media-breakpoint-down(sm){
                border-width: 10px;
              }
              @include media-breakpoint-down(xs){
                border-width: 6px;
              }
              h2{
                font-size: 1rem;
                @include media-breakpoint-down(sm) {
                  font-size: 12px;
                  font-weight: 500;
                }
              }
              @include media-breakpoint-down(xs){
                padding: 8px;
                .projDisplay{
                  display: none;
                }
              }
            }
            @include media-breakpoint-down(sm) {
              flex-direction: row;
              height: 30vh;
            }
            @include media-breakpoint-down(xs){
              height: 15vh;
            }
            @include media-breakpoint-down(sm) {
              .projOne, .projTwo{
                width: 100%;
              }
            }

            }
          }
        }
      }
      .workflowTitle{
        text-align: center;
        font-style: italic;
        @include media-breakpoint-down(md){
          font-size: 14px;
        }
      }
    }
